<template>
	<div class='orderParticulars'>
		<frame :top1="top1" :top2="top2">
			<Card style='margin-left: 200px'>
				<div style="height: 600px;">
					<div class="titles">服务信息</div>
					<div class="title">
						<slot></slot>
					</div>
					<div class="content">
						<div id="contents">
							<div class="cont">
								<table>
									<tr>
										<td class="list_left">
											服务单号
										</td>
										<td class="list_right">
											{{data.order_sn}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											申请状态
										</td>
										<td class="list_right">
											{{data.order_status_name}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											订单编号
										</td>
										<td class="list_right">
											{{data.order_sn}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											申请时间
										</td>
										<td class="list_right">
											{{data.refund_time}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											用户账号
										</td>
										<td class="list_right">
											{{data.receiving_user}}
										</td>
									</tr>
								</table>


								<table>
									<tr>
										<td class="list_left">
											订单金额
										</td>
										<td class="list_right">
											¥{{data.order_money}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											确认退款金额
										</td>
										<td class="list_right">
											¥{{data.pay_fee}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											退款方式
										</td>
										<td class="list_right">
											退回到原支付渠道
										</td>
									</tr>
									<tr>
										<td class="list_left">
											退款类型
										</td>
										<td class="list_right">
											{{data.rname}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											退款原因
										</td>
										<td class="list_right">
											{{data.refund_content}}
										</td>
									</tr>
								</table>


								<table v-if="data.order_status == 5">
									<tr>
										<td class="list_left">
											处理备注
										</td>
										<td class="list_right">
											<Input v-model="remark" placeholder="处理备注" clearable style="width: 400px" />
										</td>
									</tr>
								</table>


								<table v-else>
									<tr>
										<td class="list_left">
											处理人员
										</td>
										<td class="list_right">
											{{data.handle_user_name?data.handle_user_name:'暂无'}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											处理时间
										</td>
										<td class="list_right">
											{{data.handle_time?data.handle_time:'暂无'}}
										</td>
									</tr>
									<tr>
										<td class="list_left">
											操作备注
										</td>
										<td class="list_right">
											{{data.handle_content?data.handle_content:'暂无'}}
										</td>
									</tr>
								</table>
								<div v-if="data.order_status == 6">
									<Button type="success" @click='refund(1)'>确认退款</Button>
									<Button type="error" @click='refund(2)'>拒绝退款</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</frame>
	</div>
</template>

<script>
	import frame from '../../public/Frame.vue';
	export default {
		name: 'orderParticulars',
		data() {
			return {
				data: [],
				top1: '5-6',
				top2: ['5'],
				remark: ''
			}
		},
		components: {
			frame
		},
		created() {
			let token = this.$storage.getLocal('token');
			if (this.$request.getUrl('id')) {
				this.$request.refundOrderInfo({
					token,
					id: this.$request.getUrl('id')
				}).then(res => {
					if (res.code == 0) {
						this.data = res.data
					}
				})
			}
		},
		methods: {
			refund(obj) {
				let token = this.$storage.getLocal('token');
				this.$request.refundOrderSet({
					token,
					id: this.$request.getUrl('id'),
					content: this.remark,
					status: obj
				}).then(res => {
					console.log(res);
					 // return false
					// return false
					if (res.code == 0) {
						this.$router.go(0)
						this.$Message.success('成功！！！');
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.ivu-btn {
		margin-right: 1rem;
	}

	.list_left {
		width: 20%;
		text-align: right;
		padding-right: 0.5rem;
		font-size: 0.3rem;
		background-color: #F9FAFC;
	}

	.list_right {
		width: 80%;
		text-align: left;
		padding-left: 0.5rem;
		font-size: 0.3rem;
	}

	img {
		width: 20px;
	}

	table {
		width: 100%;
		background: #eeeeee;
		margin-bottom: 0.5rem;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.titNa {
		text-align: center;
	}

	.conBo {
		text-align: center;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}

	.shenhe {
		color: #42B983;
	}
</style>
